<template>
    <div class="wrapper">
        <div class="send-notifications-form">
            <div class="receivers">
                <h4>Получатель</h4>
                <div class="form-actions radio-wrapper">
                    <label class="form-label">
                        Все активные пользователи
                        <input type="radio" class="modern-radio" v-model="receiver" value="all" checked>
                        <span class="getters-span"></span>
                    </label>
                    <label class="form-label">
                        <p style="opacity: 0.5;">Выбрать пользователя</p>
                        <input type="radio" class="modern-radio" v-model="receiver" value="choose" disabled>
                        <span class="getters-span"></span>
                    </label>
                </div>
            </div>
            <div class="priority">
                <h4>Приоритет</h4>
                <div class="form-actions radio-wrapper">
                    <label class="form-label">
                        <p>Обычный</p>
                        <input type="radio" class="modern-radio" value="SIMPLE" v-model="priority" checked>
                        <span class="priority-span"></span>
                    </label>
                    <label class="form-label">
                        <ico name="icoError" color="#EC3323" size="40px"></ico>
                        <input type="radio" class="modern-radio" value="IMPORTANT" v-model="priority">
                        <span class="priority-span"></span>
                    </label>          
                    <label class="form-label">
                        <ico name="icoCheckCircle" color="#12AE0E" size="40px"></ico>
                        <input type="radio" class="modern-radio" value="SUCCESS" v-model="priority">
                        <span class="priority-span"></span>
                    </label>
                </div>
            </div>
            <div class="theme">
                <h4>Тема</h4>
                <div class="theme-area">
                    <textarea class="theme-textarea" v-model="tempTheme" placeholder="Тема уведомления" maxlength="60"></textarea>
                    <span class="counter">{{ counterTheme }} / 60</span>
                </div>
                
            </div>
            <div class="content">
                <h4>Содержание</h4>
                <div class="content-area">
                    <textarea class="content-textarea" v-model="tempContent" placeholder="Введите текст уведомления" maxlength="500"></textarea>
                    <span class="counter">{{ counterContent }} / 500</span>
                </div>
                
            </div>
            <div class="action-block">
                <button class="send-button btn btn-middle" disabled> Отправить на Email</button>
                <button class="send-button btn btn-middle" @click="sendNotificationFromAdmin" :disabled="counterContent == 0 || counterTheme == 0"> Отправить</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import { sendNotification } from '../../../application/requests/notificationsRequests';

    export default {
        name: "SendNotifications",
        props: ['item'],
        data() {
            return {
                tempTheme: '',
                tempContent: '',
                receiver: 'all',
                priority: 'SIMPLE'
            }
        },
        computed: {
            ...mapGetters('notifications', ['allNotifications', 'newNotifications', 'displayedNotifications']),
            ...mapGetters('profile', ['profileId']),
            counterTheme() {
                return this.tempTheme.length || 0;
            },
            counterContent() {
                return this.tempContent.length || 0;
            },
            
            
        },

        methods: {
            ...mapActions('notifications', ['getAllNotifications', 'getNewNotifications', 'getHistoryNotifications']),
            async sendNotificationFromAdmin(){
                let tempContentJSON;
                if(this.tempContent.includes('http')){
                    tempContentJSON = JSON.parse(JSON.stringify(this.tempContent).replaceAll('\\n', ` \\n `))
                    tempContentJSON = tempContentJSON.split(' ').map((item) => {
                        if(item.includes('http')){
                            item = `<a class="link-custom" href="${item}" target="_blank">ссылка</a>`
                            return item
                        }
                        return item
                    })
                    tempContentJSON = JSON.stringify(tempContentJSON.join(' '))
                }else{
                    tempContentJSON = JSON.stringify(this.tempContent)
                }
                
                const data = {
                    profilesEmail: [],
                    priority: this.priority,
                    header: JSON.stringify(this.tempTheme),
                    content: tempContentJSON
                }

                try{
                    await sendNotification(data)
                    this.getHistoryNotifications()
                    this.$toast.success('Уведомление успешно отправлено')
                }catch(e){
                    this.$toast.error(e.message)
                }   

                this.priority = 'SIMPLE',
                this.tempTheme = '',
                this.tempContent = ''


            },
        }
    }
</script>

<style lang="less">

    .wrapper{
        width: fit-content;
    }
    
    .form-actions{
        display: flex;
        gap: 30px;

        .form-label{
            display: flex;
            gap: 10px;
        }
    }

    .theme{
        position: relative;

        .theme-area{
            width: 500px;
            height: 80px;
            width: 500px;
            height: 80px;
            border: 2px solid #CFD8DD;
            border-radius: 12px;
            padding: 10px;

            .theme-textarea{
                width: 100%;
                height: 40px;
                border: 0;
                resize: none;



                &:focus-visible{
                    outline: 0px;
                }
            }
        }

        
    }

    .content{
        position: relative;

        .content-area{
            width: 500px;
            height: 250px;
            border: 2px solid #CFD8DD;
            border-radius: 12px;
            padding: 10px;

            .content-textarea{
                width: 100%;
                height: 205px;
                border: 0;
                resize: none;

                &:focus-visible{
                    outline: 0px;
                }
            }
        }
    }

    .counter{
        position: absolute;
        bottom: 12px;
        left: 12px;
        opacity: 0.5;
    }

    .action-block{
        display: flex;
        justify-content: flex-end;
        padding: 22px 0;
        gap: 20px;
    }

    input[type="radio"]{
        margin: 0;
    }

    .radio-wrapper {

        input {
            position: absolute;
            opacity: 0;

            &:checked ~ span {
                border: 6px solid #7D919B;
            }

            &:disabled ~ span {
                opacity: 0.5;
            }
        }

        label {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 17px;
            user-select: none;
            display: flex;
            align-items: center;
        }
        span {
            position: absolute;
            top: 0px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: transparent;
            border: 2px solid #7D919B;
            border-radius: 50%;
            box-sizing: border-box;
        }

        .priority-span{
            top: 9px;
        }

        
    }
    
</style>